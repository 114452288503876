<template>
    <div>
        <Footer />
        <div class="content">
            <div class="box">
                <div class="son-box">
                    <div class="left-box">
                        <div class="left-wrap">
                            <!--<div class="BigCup-logo">
                                <img src="./register/logo.png" title="大唐杯logo" alt="大唐杯logo" />
                            </div>-->
                            <!-- <div @click="goOn">首页</div> -->
                            <!-- <div class="desc-introduce">
                                <p>“大唐杯”报名登陆</p>
                            </div> -->
                            <!-- <div class="desc-warn">
                                <div class="title_o">
                                    <span>注意:</span>
                                </div>
                                <div class="title_t">
                                    <span>
                                        学生赛报名方式目前为学校教师代表统一报名,仅限学校教师申请注册报名账号,每校限制两个账号,学生请联系学校教师代表(或导师)进行报名。
                                    </span>
                                </div>
                            </div> -->

                        </div>
                    </div>
                    <div class="right-box">
                        <div class="formItems">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                    class="demo-ruleForm">
                                    <el-form-item label="" prop="account">
                                        <el-input ref="input" type="text" class="inputColorBlack" placeholder="账号/手机号"
                                            v-model="username" @keyup.enter.native="login" @blur="inputBlur">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="" prop="pass">
                                        <el-input type="password" placeholder="登陆密码" v-model="password"
                                            @keyup.enter.native="login" @blur="inputBlur">
                                        </el-input>
                                    </el-form-item>
                                    <div class="remember-box">
                                        <!--<el-checkbox v-model="checked"></el-checkbox> -->
                                        <a><span class="title" @click="retrieve">密码找回</span></a>
                                    </div>
                                </el-form>

                                <div class="button-footer">
                                    <el-row>
                                        <el-col span="8">
                                            <el-button class="" type="primary" @click="login">登录</el-button>
                                        </el-col>
                                        <el-col span="8" :offset="2">
                                            <el-button @click="onSubmit">没有账号?&nbsp;&nbsp;点我进行注册</el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                              

                            </div>
                    </div>
                </div>
            </div>
        </div>
        <Bottom />
    </div>
</template>
<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import { method } from 'lodash';
import router from '../../router';
import {getLogin} from "api/apis.js"
export default {
    name: 'Register',
    components: {
        Footer,
        Bottom
    },
    data() {
        return {
            checked: false,
            username:"",
            password:"",
            // rules: {
            //     account: [
            //         { required: true, message: '请输入账号', trigger: 'blur' },
            //         // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
            //     ],
            //     pass: [
            //         { required: true, message: '请输入密码', trigger: 'blur' }
            //     ],
            // }
        }
    },
    created() { 
        let token = window.localStorage.getItem("userToken");
        if(!token) {
            this.$router.push('/Register')
        } else {
            this.$router.push('/backHome')
        }
    },
    mounted() { },
    methods: {
        goOn() {
            this.$router.push('/Enrollment')
        },
        inputBlur(e) {
            if(e.isTrusted) {
                this.$nextTick(() => { // 表单数据置空 并且移除校验
                    this.$refs['ruleForm'].resetFields();
                })
            }
        },
        //提交
        onSubmit() {
            console.log(this.ruleForm)
            this.$router.push('/registered')
        },
        // 登录
        login() {
            if(this.username != "" && this.password != ""){
                let dataobj = new FormData();
                dataobj.append('username',this.username);
                dataobj.append('password',this.password);
                getLogin.login(dataobj).then(res=>{
                    if(res.code == 200){
                        console.log(res)
                    console.log(JSON.parse(res.user))

                        const obj = JSON.parse(res.user)
                        this.$store.commit('UPuser',obj)

                        this.$message({
                            message: '恭喜你，登录成功！',
                            type: 'success'
                        });
                    setTimeout(() => {
                    this.$router.push('/upload')
                    }, 1000);
                    }else{
                        this.$message.error('用户名密码错误！');
                    }
                })
                // 判断校验是否成功  （取到表单对象） 点击等候先校验 valid校验成功返回true
                this.$refs.ruleForm.validate(valid => {
                    if (!valid) {
                        return
                    }
                    // 发送请求判断是否登录成功
                    console.log('点击登录发送请求')
                    this.$router.push('')
                })
            }else{
                this.$message.error('请输入完整信息！');
            }
        },
        //密码找回
        retrieve() {
            this.$router.push('/back')
            console.log('密码找回!');
        },
        //选中找回密码
        handleChecked(val) {
            if (!val) return;
        }
    }

}
</script>
<style lang="scss" scoped>
ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

:deep(.el-form-item__content) {
    margin-left: 0 !important;
}

:deep(.el-input) {
    width: 3.54rem;
    height: 0.5rem;
    opacity: 1;
    background-color: #FFFFFF !important;
    // border: 1px solid #C1BBBB !important;
}

:deep(.el-form-item) {
    margin-bottom: 0.24rem;
}

:deep(.el-input__inner) {
    color: #606266;
    height: 0.3rem !important;
    background-color: white;
}

:deep(input:-webkit-autofill) {
    background-color: transparent;
    background-image: none;
    // -webkit-text-fill-color: #fff !important; // 记住密码的字的颜色,我的项目中没用到这个
    transition: background-color 5000s ease-in-out 0s; // 延时渲染背景色来去除背景色
    // caret-color: #fff; // 光标颜色
    box-shadow: 0 0 0px 1000px white inset;
}

:deep(.el-button--primary) {
    margin-left: .1rem;
    background: #5C4F7D;
    width: 1.2rem;
    height: 0.4rem;
    border-radius: 0;
    box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.25);

    span {
        font-size: 0.12rem;
    }
}

:deep(.el-col-offset-2){
    margin-left:5.333333% !important;
}

:deep(.el-col-offset-2 .el-button) {
    margin-left: .3rem;
    width: 1.6rem;
    opacity: 0.8;
    height: 0.4rem !important;
    background: #7A896C;
    border: 0.01rem solid #5065eb;
    padding:0.08rem 0.09rem;
    border-radius: 0 !important;
    box-sizing:border-box;
    span {
        font-size: 0.12rem;
        color: #fff;
    }

}

:deep(.el-checkbox) {
    width: 3.54rem !important;
    display: inline-block !important;
}

:deep(.el-checkbox__input) {
    // float: left;
}

:deep(.el-input__wrapper) {
    // border-radius: 0 !important;
    // padding: 0 !important;
    border-radius: 0;
}

:deep(.el-checkbox__label) {
    float: right;
}

// .inputColorBlack :deep(.el-input__inner){
//         background-color: #FFFFFF !important;
//   }
@import './index.less';
</style>
